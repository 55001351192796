import React from "react";
import { Link } from "react-router-dom";
import { editIcon } from "../../../../icons";
import moment from "moment";

const ProfileList = ({ page_number, list, tableHeadItemsForProfiles }) => {
  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <div className="">
          <table className="custom_data_table">
            <thead className="custom_data_table_head">
              <tr>
                {tableHeadItemsForProfiles.map((item, index) => {
                  return (
                    <th
                      className="custom_data_table_heading text-center"
                      key={index}
                    >
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="custom_data_table_body">
              {list?.map((item, i) => (
                <tr className="custom_data_table_row" key={item.id}>
                  <td className="custom_data_table_item table_item">
                    {i + 1 + page_number * 10}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.name}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.created_at &&
                      moment(item.created_at).format("DD/MM/YYYY")}
                  </td>
                  <td className="custom_data_table_item custom_data_table_view_edit_item table_item">
                    <div className="custom_data_table_view_edit_btn_item_row">
                      <Link
                        aria-label="edit"
                        className="custom_data_table_view_edit_item_btn"
                        to={`/dtv-dynamic-profile/edit/${item.id}`}
                      >
                        {editIcon}
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ProfileList;
