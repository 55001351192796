import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../CommonComponent/Loader";
import AddProfileForm from "./AddProfileForm";
import { getProfileDetails } from "../../../../store/slice/profileSlice";

const EditProfilePage = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const profileDetail = useSelector((state) => state.profile.profileDetail);
  const loading = useSelector((state) => state.profile.loader);

  useEffect(() => {
    dispatch(getProfileDetails({ data: params.id }));
  }, [params]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <AddProfileForm editDetail={profileDetail} isEdit={true} />
      )}
    </>
  );
};

export default EditProfilePage;
