import React, { useEffect, useState } from "react";
import { backBtnArrow } from "../../icons";
import { useNavigate, useParams } from "react-router-dom";
import VersionProfileList from "../../component/Common/PageComponent/Version/VersionProfileList";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVersionProfile,
  getVersionProfiles,
  reqToUpdateVersionProfileList,
} from "../../store/slice/versionSlice";
import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import Loader from "../../component/Common/CommonComponent/Loader";
import Button from "../../component/Common/CommonComponent/Button";
import { Modal } from "react-bootstrap";
import DeleteVersionModal from "../../component/Common/Modals/DeleteVersionModal";

const VersionProfilePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const versionProfileList = useSelector(
    (state) => state.version.versionProfileList
  );
  const loader = useSelector((state) => state.version.loader);

  const [showDeleteVerProf, setShowDeleteVerProf] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState(false);
  const handleDeleteVerProfModal = (item) => {
    if (item) {
      setDeleteModalId(item.id);
    } else {
      setDeleteModalId("");
    }
    setShowDeleteVerProf(!showDeleteVerProf);
  };

  const tableHeadItemsForVersionProfiles = [
    "ID",
    "NAME",
    "TOTAL BYTES",
    "DESCRIPTION",
    "CREATED AT",
    "ACTION",
  ];

  const handleDeleteVersionProfile = (data) => {
    dispatch(
      deleteVersionProfile({
        data: deleteModalId,
        onSuccess: (res) => {
          dispatch(reqToUpdateVersionProfileList({ id: deleteModalId }));
          handleDeleteVerProfModal();
        },
        onFailure: () => {
          handleDeleteVerProfModal();
        },
      })
    );
  };

  useEffect(() => {
    if (params) {
      dispatch(
        getVersionProfiles({
          data: params.id,
        })
      );
    }
  }, [params]);

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading="VERSION PROFILES" />

      <div className="project_edit_main_content">
        {loader ? (
          <Loader />
        ) : (
          <>
            <div className="project_edit_main_link d-flex align-items-center justify-content-between">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
              <Button
                className="project_submit_bottom_btn"
                buttonClassName="comman_btn"
                onClick={() => navigate(`/dtv-profile-assign/${params.id}`)}
                text="Assign profiles"
              />
            </div>
            <VersionProfileList
              version_id={params.id}
              list={versionProfileList}
              tableHeaders={tableHeadItemsForVersionProfiles}
              handleDelete={handleDeleteVerProfModal}
            />

            <Modal
              className="modal_main_component modal_background_content"
              // dialogClassName="custom-dialog"
              show={showDeleteVerProf}
              onHide={handleDeleteVerProfModal}
              centered
            >
              <DeleteVersionModal
                deleteModalId={deleteModalId}
                title={"Delete version profile"}
                description={"you want to delete this version profile!"}
                closeDeleteModal={handleDeleteVerProfModal}
                deleteVehHandler={handleDeleteVersionProfile}
              />
            </Modal>
          </>
        )}
      </div>
    </div>
  );
};

export default VersionProfilePage;
