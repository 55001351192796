// Version form VALIDATIIONS
export const dynamicProfileFormValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors
) => {
  if ("profile_name" in fieldValues) {
    temp.profile_name = fieldValues.profile_name.trim()
      ? ""
      : "Profile name is required!";
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
