import { toast } from "react-toastify";
import { closeIcon } from "../icons";
const moment = require("moment-timezone");

const toastList = new Set();
const MAX_TOAST = 1;

export const defaultTimeZone = moment.tz.guess();

// One time toast message show //
export function notify(test, message) {
  if (toastList.size < MAX_TOAST) {
    let id;
    if (message === "success") {
      id = toast.success(test, {
        // I'm using the onClose hook here to remove the id
        // from the set
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    } else {
      id = toast.error(test, {
        // I'm using the onClose hook here to remove the id
        // from the set
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    }
    toastList.add(id);
  }
}

// after uploading images preview it

export const imagePreviewProps = (image, src, imageRemoveHandler) => {
  return (
    image && (
      <>
        <img src={src} alt="benefits logo" />
        <button className="image_remove_btn" onClick={imageRemoveHandler}>
          {closeIcon}
        </button>
      </>
    )
  );
};

// For Drag & Drop functionality

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const generateUniqueId = () => {
  return Number(`${Date.now()}${Math.floor(Math.random() * 1000)}`);
};

export const getByteSize = (valueTypeToByteSize, valueType) => {
  return valueTypeToByteSize[valueType] || 0; // Default to 0 if not found
};

export const findValueTypeByValue = (array, value) => {
  return array.find((item) => item.value === value);
};
