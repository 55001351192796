// profile parameter form VALIDATIIONS
export const profileParameterFormValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors
) => {
  if ("name" in fieldValues) {
    temp.name = fieldValues.name.trim() ? "" : "Name is required!";
  }
  if ("byteSize" in fieldValues) {
    temp.byteSize = fieldValues.byteSize ? "" : "Byte size is required!";
  }
  if ("configPart" in fieldValues) {
    temp.configPart = fieldValues.configPart ? "" : "Config part is required!";
  }
  if ("valueType" in fieldValues) {
    temp.valueType = fieldValues.valueType ? "" : "Value type is required!";
  }
  if ("value" in fieldValues) {
    temp.value = fieldValues.value.trim() ? "" : "value is required!";
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
