// Version form VALIDATIIONS
export const assignProfileValidate = (
  fieldValues,
  temp,
  setValues,
  values,
  setErrors
) => {
  if ("profile" in fieldValues) {
    temp.profile = fieldValues.profile.trim() ? "" : "Profile is required!";
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
