import React from "react";
import CommonHeading from "../../CommonComponent/CommonHeading";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dynamicProfileFormValidate } from "./DynamicProfileCommon";
import { useForm } from "../../../../hooks/useForm";
import {
  addProfile,
  updateProfile,
} from "../../../../store/slice/profileSlice";
import Loader from "../../CommonComponent/Loader";
import { backBtnArrow } from "../../../../icons";
import Input from "../../CommonComponent/Input";
import Button from "../../CommonComponent/Button";
import { toast } from "react-toastify";
import { routes } from "../../../../constants";

const AddProfileForm = ({ editDetail, isEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector((state) => state.profile.loader);

  const initialFValues = {
    profile_name: editDetail && editDetail.name ? editDetail.name : "",
  };

  // validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return dynamicProfileFormValidate(
      fieldValues,
      temp,
      setValues,
      values,
      setErrors
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleProfileSubmit = () => {
    if (validate()) {
      const payload = {
        name: values.profile_name,
      };

      // Dispatch the final API call to add the version
      if (isEdit) {
        dispatch(
          updateProfile({
            data: { payload, id: editDetail.id },
            onSuccess: (res) => {
              setValues({ profile_name: "" });
              setErrors({});
              toast.success("profile updated successfully!", {
                autoClose: 3000,
              });
              navigate(`${routes.dynamicProfile}/page/1`);
            },
            onFailure: (error) => {
              toast.error(error, {
                autoClose: 3000,
              });
            },
          })
        );
      } else {
        dispatch(
          addProfile({
            data: payload,
            onSuccess: (res) => {
              setValues({ profile_name: "" });
              setErrors({});
              toast.success("profile added successfully!", {
                autoClose: 3000,
              });
              navigate(`${routes.dynamicProfile}/page/1`);
            },
            onFailure: (error) => {
              console.error("Failed to add profile:", error);
              toast.error(error, {
                autoClose: 3000,
              });
            },
          })
        );
      }
    }
  };
  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={`${isEdit ? "EDIT PROFILE" : "ADD PROFILE"}`} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>
          <div className="create_from_row">
            <div className="">
              <Input
                className="project_edit_feature_input"
                errorClassName="err_text"
                type="text"
                placeholder="Enter Profile Name"
                label="Profile name"
                id="profile_name"
                name="profile_name"
                value={values.profile_name}
                onChange={handleInputChange}
                error={errors.profile_name || ""}
              />
            </div>
          </div>
          <Button
            className="project_submit_bottom_btn center_back_btn"
            buttonClassName="comman_btn"
            onClick={handleProfileSubmit}
            text={`${isEdit ? "Update" : "Add"}`}
          />
        </>
      )}
    </div>
  );
};

export default AddProfileForm;
