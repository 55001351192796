import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeIcon, editIcon } from "../../../icons";
import {
  addRequestReply,
  getSupportRequestById,
  updateRequestReply,
} from "../../../store/slice/supportReqSlice";
import Loader from "../CommonComponent/Loader";
import { useState } from "react";
import moment from "moment";
import { defaultTimeZone } from "../../../helpers/commonFunction";
import { useRef } from "react";

const ViewSupportDetail = ({ closeModal, reqId, prevReqId }) => {
  const dispatch = useDispatch();

  const reqDetail = useSelector((state) => state.supportReq.supportReqDetail);
  const isLoading = useSelector((state) => state.supportReq.extraLoader);
  const msgLoader = useSelector((state) => state.supportReq.msgLoader);

  const [replyMsg, setReplyMsg] = useState("");
  const [chatTab, setChatTab] = useState(false);
  const [onEditMsgId, setOnEditMsgId] = useState("");
  const bottomRef = useRef(null);

  useEffect(() => {
    if (prevReqId !== reqId) {
      dispatch(getSupportRequestById({ id: reqId }));
    }
  }, [reqId]);

  const openChatTab = () => {
    setChatTab(chatTab ? false : true);
    setReplyMsg("");
    setOnEditMsgId("");
  };

  const msgReplyChange = (e) => {
    setReplyMsg(e.target.value);
  };

  const editMsgHandler = (msg) => {
    setOnEditMsgId(msg.id);
    setReplyMsg(msg.message);
  };

  const closeEditMsg = () => {
    setOnEditMsgId("");
    setReplyMsg("");
  };

  const sendReplyHandler = () => {
    const data = {
      support_request_id: reqDetail.id,
      message: replyMsg,
      sender: 1,
    };
    if (onEditMsgId) {
      dispatch(
        updateRequestReply({
          id: onEditMsgId,
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              dispatch(getSupportRequestById({ id: reqId }));
              setOnEditMsgId("");
              setReplyMsg("");
            }
          },
        })
      );
    } else {
      dispatch(
        addRequestReply({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              dispatch(getSupportRequestById({ id: reqId }));
              setReplyMsg("");
            }
          },
        })
      );
    }
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [reqDetail, chatTab]);

  return (
    <>
      <div className="modal_container">
        {isLoading && !chatTab ? (
          <Loader />
        ) : (
          <>
            <div className="modal_heading">
              <h1 className="modal_main_heading">Request Detail</h1>
              <button
                className={`comman_btn open_chat_btn ${
                  chatTab && "close_chat"
                }`}
                onClick={openChatTab}
              >
                {!chatTab ? "Open Chat" : "Close Chat"}
              </button>
              <button className="modal_close_btn" onClick={() => closeModal()}>
                {closeIcon}
              </button>
            </div>
            {!chatTab ? (
              <div className="modal_detail_container">
                {reqDetail.attachment && (
                  <div className="modal_detail_top_content">
                    <div className="modal_detail_top_first_content">
                      <img
                        src={`${process.env.REACT_APP_FILE_BASE_URL}/${reqDetail.attachment}`}
                      />
                      <p>{reqDetail.status}</p>
                    </div>
                  </div>
                )}
                <div className="modal_detail_container_grid only_detail_modal">
                  {!reqDetail.attachment && (
                    <div className="modal_detail_content">
                      <h2 className="modal_detail_content_heading">
                        Request Status
                      </h2>
                      <p>{reqDetail.status}</p>
                    </div>
                  )}
                  <div className="modal_detail_content">
                    <h2 className="modal_detail_content_heading">
                      Request subject
                    </h2>
                    <p>{reqDetail.subject}</p>
                  </div>
                  <div className="modal_detail_content">
                    <h2 className="modal_detail_content_heading">
                      Request Date & Time
                    </h2>
                    <p>
                      {moment
                        .utc(reqDetail.request_date, "YYYYMMDD HH:mm:ss")
                        .clone()
                        .tz(defaultTimeZone)
                        .format("DD/MM/YYYY, hh:mm A")}
                    </p>
                  </div>
                  <div className="modal_detail_content">
                    <h2 className="modal_detail_content_heading">User Name</h2>
                    <p>
                      {reqDetail.user && reqDetail.user.name
                        ? reqDetail.user.name
                        : "-"}
                    </p>
                  </div>
                  <div className="modal_detail_content">
                    <h2 className="modal_detail_content_heading">User Email</h2>
                    <p>
                      {reqDetail.user && reqDetail.user.email
                        ? reqDetail.user.email
                        : "-"}
                    </p>
                  </div>
                  <div className="modal_detail_content">
                    <h2 className="modal_detail_content_heading">
                      User Contact No.
                    </h2>
                    <p>
                      {reqDetail.user && reqDetail.user.phone
                        ? reqDetail.user.phone
                        : "-"}
                    </p>
                  </div>

                  <div className="modal_detail_content">
                    <h2 className="modal_detail_content_heading">UUID</h2>
                    <p>
                      {reqDetail.shredder && reqDetail.shredder.shredder_uuid
                        ? reqDetail.shredder.shredder_uuid
                        : "-"}
                    </p>
                  </div>
                  <div className="modal_detail_content span_2">
                    <h2 className="modal_detail_content_heading">
                      Description
                    </h2>
                    <p>{reqDetail.description}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="modal_detail_container">
                <div className="zu_chat_msg_content">
                  <div className="zu_chat_msg_header">
                    <div className="zu_chat_msg_header_name_content">
                      <div className="zu_chat_msg_header_name_text">
                        <h4>
                          {reqDetail.user && reqDetail.user.name
                            ? reqDetail.user.name
                            : "-"}
                        </h4>
                        <p>
                          {reqDetail.subject} ({reqDetail.status})
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="zu_chat_msg_container d-block">
                    {reqDetail.replies?.length === 0 ? (
                      <div className="zu_chat_msg_box_content" ref={bottomRef}>
                        <div className="zu_chat_msg_box_time">
                          <span>Start a Conversation</span>
                        </div>
                      </div>
                    ) : (
                      reqDetail.replies.map((msg, i) => {
                        return (
                          <div key={msg.id}>
                            <div
                              className="zu_chat_msg_box_content"
                              ref={bottomRef}
                            >
                              {i === 0 ? (
                                <div className="zu_chat_msg_box_time">
                                  <span>
                                    {moment
                                      .utc(
                                        reqDetail.replies[0].created_at,
                                        "YYYYMMDD HH:mm:ss"
                                      )
                                      .clone()
                                      .tz(defaultTimeZone)
                                      .format("DD/MM/YYYY")}
                                  </span>
                                </div>
                              ) : moment
                                  .utc(
                                    reqDetail.replies[i].created_at,
                                    "YYYYMMDD HH:mm:ss"
                                  )
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY") !==
                                moment
                                  .utc(
                                    reqDetail.replies[i - 1].created_at,
                                    "YYYYMMDD HH:mm:ss"
                                  )
                                  .clone()
                                  .tz(defaultTimeZone)
                                  .format("DD/MM/YYYY") ? (
                                <div className="zu_chat_msg_box_time">
                                  <span>
                                    {moment
                                      .utc(
                                        reqDetail.replies[i].created_at,
                                        "YYYYMMDD HH:mm:ss"
                                      )
                                      .clone()
                                      .tz(defaultTimeZone)
                                      .format("DD/MM/YYYY")}
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            {msg.sender === 2 ? (
                              <div className="zu_chat_msg_recive">
                                <div className="zu_chat_msg_recive_text">
                                  <span className="zu_chat_msg_recive_text_name">
                                    {reqDetail.user?.name} |{" "}
                                    {moment
                                      .utc(msg.created_at, "YYYYMMDD HH:mm:ss")
                                      .clone()
                                      .tz(defaultTimeZone)
                                      .format("hh:mm A")}
                                  </span>
                                  <div className="zu_chat_msg_recive_text_msg_box_row">
                                    <div className="zu_chat_msg_recive_text_msg_box">
                                      <pre className="zu_chat_msg_recive_text_msg">
                                        <p>{msg.message}</p>
                                      </pre>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="zu_chat_msg_send">
                                  <div className="zu_chat_msg_send_text">
                                    <span className="zu_chat_msg_send_text_name">
                                      {moment
                                        .utc(
                                          msg.created_at,
                                          "YYYYMMDD HH:mm:ss"
                                        )
                                        .clone()
                                        .tz(defaultTimeZone)
                                        .format("hh:mm A")}
                                    </span>
                                    <div className="zu_chat_msg_send_text_msg_box_row">
                                      <div className="zu_chat_msg_send_text_msg_box">
                                        <pre className="zu_chat_msg_send_text_msg">
                                          <p>{msg.message}</p>
                                        </pre>
                                      </div>
                                      <div className="chat_edit_btn_container">
                                        <button
                                          className="chat_edit_btn"
                                          onClick={() => editMsgHandler(msg)}
                                        >
                                          {editIcon}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {((isLoading && chatTab) || msgLoader) && (
                                  <div className="zu_chat_msg_send">
                                    <div className="zu_chat_msg_send_text">
                                      <div className="zu_chat_msg_send_text_msg_box_row">
                                        <div className="zu_chat_msg_send_text_msg_box">
                                          <pre className="zu_chat_msg_send_text_msg">
                                            <p>Loading...</p>
                                          </pre>
                                        </div>
                                        <div className="chat_edit_btn_container"></div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div className="zu_chat_msg_write_content">
                    {onEditMsgId && (
                      <span className="edit_mode_enable">edit mode</span>
                    )}
                    <input
                      className="zu_chat_input"
                      onChange={(e) => msgReplyChange(e)}
                      value={replyMsg}
                    />
                    {onEditMsgId && (
                      <button
                        className="edit_mode_close_btn"
                        onClick={() => closeEditMsg()}
                      >
                        {closeIcon}
                      </button>
                    )}
                    <button
                      className="send_msg_btn"
                      onClick={() => sendReplyHandler()}
                      disabled={
                        replyMsg.trim() == "" || isLoading || msgLoader
                          ? true
                          : false
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ViewSupportDetail;
