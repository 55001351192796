import React, { useEffect, useState, version } from "react";
import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import { backBtnArrow } from "../../icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../store/slice/profileSlice";
import { useForm } from "../../hooks/useForm";
import Select from "react-select";
import { getVersionProfiles } from "../../store/slice/versionSlice";
import { assignProfileValidate } from "../../component/Common/PageComponent/Version/AssignProfileCommon";
import AssignVersionParameters from "../../component/Common/PageComponent/Version/AssignVersionParameters";
import Button from "../../component/Common/CommonComponent/Button";
import TextArea from "../../component/Common/CommonComponent/TextArea";

const AssignProfiles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const profileList = useSelector((state) => state.profile.profileList);
  const totalCount = useSelector((state) => state.profile.profileListCount);
  const versionProfileList = useSelector(
    (state) => state.version.versionProfileList
  );

  const initialValues = {
    name: "",
    byteSize: "",
    configPart: "",
    valueType: "",
    value: "",
    signed: false,
  };

  const valueType = [
    { label: "VESC_TX_UNDEFINED", value: 0 },
    { label: "VESC_TX_UINT8", value: 1 },
    { label: "VESC_TX_INT8", value: 2 },
    { label: "VESC_TX_UINT16", value: 3 },
    { label: "VESC_TX_INT16", value: 4 },
    { label: "VESC_TX_UINT32", value: 5 },
    { label: "VESC_TX_INT32", value: 6 },
    { label: "VESC_TX_DOUBLE16", value: 7 },
    { label: "VESC_TX_DOUBLE32", value: 8 },
    { label: "VESC_TX_DOUBLE32_AUTO", value: 9 },
  ];

  const valueTypeToByteSize = {
    VESC_TX_UINT8: {
      value: 1,
      label: 1,
    },
    VESC_TX_INT8: {
      value: 1,
      label: 1,
    },
    VESC_TX_UINT16: {
      value: 2,
      label: 2,
    },
    VESC_TX_INT16: {
      value: 2,
      label: 2,
    },
    VESC_TX_DOUBLE16: {
      value: 2,
      label: 2,
    },
    VESC_TX_UINT32: {
      value: 4,
      label: 4,
    },
    VESC_TX_INT32: {
      value: 4,
      label: 4,
    },
    VESC_TX_DOUBLE32: {
      value: 4,
      label: 4,
    },
    VESC_TX_DOUBLE32_AUTO: {
      value: 4,
      label: 4,
    },
  };

  const [forms, setForms] = useState([initialValues]);

  useEffect(() => {
    dispatch(getProfile({ start: 0, limits: "", search: "" }));
  }, []);

  const getProfileParameters = () => {
    dispatch(
      getVersionProfiles({
        data: params.id,
      })
    );
  };

  const profilesOption =
    profileList && profileList.length > 0
      ? profileList
          .filter(
            (profile) =>
              !versionProfileList.some(
                (versionProfile) => versionProfile.profile.name === profile.name
              )
          )
          .map((item, index) => {
            return {
              label: item.name,
              value: item.id,
            };
          })
      : [];

  const initialFValues = {
    profile: "",
    description: ""
  };

  // validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return assignProfileValidate(
      fieldValues,
      temp,
      setValues,
      values,
      setErrors
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate,
    false
  );

  const onModelIdchange = (e) => {
    setValues({
      ...values,
      profile: e,
    });
    setErrors({
      ...errors,
      profile: "",
    });
    getProfileParameters(e.value);
  };

  const onDescriptionChange = (e) => {
    setValues({
      ...values,
      description: e.target.value,
    });
  };

  const findValueTypeByValue = (array, value) => {
    return array.find((item) => item.value === value);
  };

  // const getByteSize = (valueType) => {
  //   return valueTypeToByteSize[valueType] || 0; // Default to 0 if not found
  // };

  const createParametersData = (formData) => {
    if (Array.isArray(formData) && formData.length > 0) {
      return formData.map((item) => ({
        id: item.id,
        name: item.name || "",
        valueType: findValueTypeByValue(valueType, item.valueType) || "",
        signed: item.signed || false,
        byteSize: { value: item.byteSize, label: item.byteSize } || 0,
        value: "",
        configPart:
          (item.configPart === "motor"
            ? { value: "motor", label: "Motor" }
            : { value: "app", label: "App" }) || "",
      }));
    }
    return []; // Return an empty array if data is invalid
  };

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading="ASSIGN PROFILES" />

      <div className="project_edit_main_content">
        {/* {loader ? (
          <Loader />
        ) : ( */}
        <>
          <div className="project_edit_main_link d-flex align-items-center justify-content-between">
            <button className="go_back_btn" onClick={() => navigate(-1)}>
              {backBtnArrow} Back
            </button>
          </div>

          <div className="d-flex justify-content-between flex-wrap">
            <div className="project_edit_feature_input w-25">
              <h4 className="project_text_area_label">Profiles</h4>

              <Select
                classNamePrefix="project_common_select_input"
                errorClassName="err_text"
                type="text"
                placeholder="Profiles..."
                label="Select profile"
                id="profile"
                name="profile"
                //   isClearable={true}
                isSearchable={true}
                onChange={onModelIdchange}
                value={values.profile}
                //   error={errors?.profile || ""}
                options={profilesOption}
              />
              {errors?.profile && (
                <span className="err_text">{errors.profile}</span>
              )}
            </div>

            {values.profile && versionProfileList &&
              versionProfileList.length > 0 &&
              profilesOption &&
              profilesOption.length > 0 && (
                <div className="project_edit_feature_input w-25">
                  <h4 className="project_text_area_label"></h4>
                  <Button
                    className="project_submit_bottom_btn"
                    buttonClassName="comman_btn"
                    onClick={() =>
                      setForms(
                        createParametersData(versionProfileList[0].parametres)
                      )
                    }
                    text="Copy parameter"
                  />
                </div>
              )}
            {values.profile && (
              <div className="project_edit_feature_input w-75">
                <TextArea
                  name="description"
                  label="Description"
                  value={values?.description}
                  onChange={onDescriptionChange}
                  placeholder="About Profile"
                  rows="4"
                />
              </div>
            )}

          </div>

          <div>
            {values.profile && (
              <>
              <h6 className="mb-0 mt-5">Parameters for <strong>{values.profile.label}</strong> profile</h6>
              <AssignVersionParameters
                profile_id={values.profile.value}
                description={values.description}
                data={versionProfileList}
                initialFValues={initialValues}
                forms={forms}
                setForms={setForms}
                valueType={valueType}
                valueTypeToByteSize={valueTypeToByteSize}
              />
              </>
            )}
          </div>
        </>
        {/* )} */}
      </div>
    </div>
  );
};

export default AssignProfiles;
