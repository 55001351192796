import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CommonHeading from "../../component/Common/CommonComponent/CommonHeading";
import Pagination from "../../component/Pagination/Pagination";
import Loader from "../../component/Common/CommonComponent/Loader";
import { getProfile } from "../../store/slice/profileSlice";
import ProfileList from "../../component/Common/PageComponent/DynamicProfile/ProfileList";
import { routes } from "../../constants";

const DynamicProfilePage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef(false);

  const { page_number } = params;

  const [pageNumber, setPageNumber] = useState(page_number - 1);

  const profileList = useSelector((state) => state.profile.profileList);
  const totalCount = useSelector((state) => state.profile.profileListCount);
  const isLoading = useSelector((state) => state.profile.loader);

  useEffect(() => {
    if (ref.current) {
      dispatch(
        getProfile({ start: (page_number - 1) * 10, limits: 10, search: "" })
      );
    }
  }, [pageNumber]);

  useEffect(() => {
    dispatch(
      getProfile({ start: (page_number - 1) * 10, limits: 10, search: "" })
    );
    ref.current = true;
  }, []);

  const onPageChange = (page_number) => {
    if (ref.current) {
      setPageNumber(page_number);
    }
  };

  const tableHeadItemsForProfiles = ["ID", "NAME", "CREATED AT", "ACTION"];

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading="DYNAMIC PROFILE" />

        <>
          <div className="project_edit_main_content">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <div className="creat_edit_project_btn_row_flex">
                  <div>
                    <Link
                      className="comman_btn ml-auto"
                      to={`${routes.addProfile}`}
                    >
                      Add Profile
                    </Link>
                  </div>
                </div>
                <div className="custom_data_table_content">
                  <ProfileList
                    page_number={pageNumber}
                    list={profileList}
                    tableHeadItemsForProfiles={tableHeadItemsForProfiles}
                  />
                </div>

                {totalCount > 0 && (
                  <Pagination
                    totalRecords={totalCount}
                    onPageChange={onPageChange}
                  />
                )}
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default DynamicProfilePage;
