import React from "react";
import Input from "../../CommonComponent/Input";
import Button from "../../CommonComponent/Button";
import Select from "react-select";
import { backBtnArrow } from "../../../../icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { profileParameterFormValidate } from "./profileParametersCommon";
import { useForm } from "../../../../hooks/useForm";
import { useDispatch } from "react-redux";
import { editVersionProfiles } from "../../../../store/slice/versionSlice";
import {
  findValueTypeByValue,
  getByteSize,
} from "../../../../helpers/commonFunction";
import CommonHeading from "../../CommonComponent/CommonHeading";
import { toast } from "react-toastify";

const EditProfileParameterForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { item, parametres, isEdit, total_bytes, profile, editId, version_id } =
    location.state;
  const params = useParams();

  const valueType = [
    { label: "VESC_TX_UNDEFINED", value: 0 },
    { label: "VESC_TX_UINT8", value: 1 },
    { label: "VESC_TX_INT8", value: 2 },
    { label: "VESC_TX_UINT16", value: 3 },
    { label: "VESC_TX_INT16", value: 4 },
    { label: "VESC_TX_UINT32", value: 5 },
    { label: "VESC_TX_INT32", value: 6 },
    { label: "VESC_TX_DOUBLE16", value: 7 },
    { label: "VESC_TX_DOUBLE32", value: 8 },
    { label: "VESC_TX_DOUBLE32_AUTO", value: 9 },
  ];

  const valueTypeToByteSize = {
    VESC_TX_UINT8: {
      value: 1,
      label: 1,
    },
    VESC_TX_INT8: {
      value: 1,
      label: 1,
    },
    VESC_TX_UINT16: {
      value: 2,
      label: 2,
    },
    VESC_TX_INT16: {
      value: 2,
      label: 2,
    },
    VESC_TX_DOUBLE16: {
      value: 2,
      label: 2,
    },
    VESC_TX_UINT32: {
      value: 4,
      label: 4,
    },
    VESC_TX_INT32: {
      value: 4,
      label: 4,
    },
    VESC_TX_DOUBLE32: {
      value: 4,
      label: 4,
    },
    VESC_TX_DOUBLE32_AUTO: {
      value: 4,
      label: 4,
    },
  };

  const configOptions = [
    {
      value: "app",
      label: "App",
    },
    {
      value: "motor",
      label: "Motor",
    },
  ];

  const bytesOptions = [
    {
      value: 1,
      label: 1,
    },
    {
      value: 2,
      label: 2,
    },
    {
      value: 4,
      label: 4,
    },
  ];

  const initialFValues = {
    name: item ? item.name : "",
    byteSize: item
      ? {
          value: item.byteSize,
          label: item.byteSize,
        }
      : "",
    configPart: item
      ? {
          value: item.configPart,
          label: item.configPart === "motor" ? "Motor" : "App",
        }
      : "",
    valueType: item ? findValueTypeByValue(valueType, item.valueType) : "",
    value: item ? item.value : "",
    signed: item ? item.signed : false,
  };

  // validation
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return profileParameterFormValidate(
      fieldValues,
      temp,
      setValues,
      values,
      setErrors
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSelectChange = (e, name) => {
    if (name === "valueType") {
      if (
        e.label !== "VESC_TX_UNDEFINED" &&
        e.label !== "VESC_TX_UINT8" &&
        e.label !== "VESC_TX_UINT16" &&
        e.label !== "VESC_TX_UINT32"
      ) {
        setValues({
          ...values,
          signed: true,
          valueType: e,
          byteSize: getByteSize(valueTypeToByteSize, e.label),
        });
      } else {
        setValues({
          ...values,
          valueType: e,
          signed: false,
          byteSize: getByteSize(valueTypeToByteSize, e.label),
        });
      }
    } else if (name === "signed") {
      setValues({
        ...values,
        [name]: e.target.checked,
      });
    } else {
      setValues({
        ...values,
        [name]: e,
      });
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      const properties = {
        id: item.id,
        name: values.name || "",
        valueType: values.valueType?.value || "",
        signed: values.signed || false,
        byteSize: values.byteSize?.value || 0,
        value: values.value || "",
        configPart: values.configPart?.value || "",
      };
      const updatedParameters =
        parametres && parametres.length > 0
          ? parametres.map((param) =>
              param.id === item.id ? properties : param
            )
          : [];

      const totalBytes = updatedParameters.reduce(
        (sum, param) => sum + param.byteSize,
        0
      );

      const payload = {
        parametres: updatedParameters,
        total_bytes: totalBytes,
      };

      dispatch(
        editVersionProfiles({
          data: { id: editId, data: payload },
          onSuccess: (res) => {
            navigate(`/dtv-version-profile/${version_id}`);
            if (res.data.message) {
              toast.success(res.data.message, {
                autoClose: 3000,
              });
            }
            // navigate(-1);
            // dispatch(
            //   getVersionProfiles({
            //     data: version_id,
            //   })
            // );
          },
          onFailure: () => {},
        })
      );
    }
  };

  return (
    <>
      <div className="project_edit_main_content">
        <CommonHeading heading={"EDIT PARAMETER"} />
        <div className="project_edit_main_link">
          <div className="comman_center_btn_container">
            <button className="go_back_btn" onClick={() => navigate(-1)}>
              {backBtnArrow} Back
            </button>
          </div>
        </div>

        <div className="create_from_row">
          <div className="project_edit_feature_input mt-0">
            <Input
              className="project_edit_feature_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter Name"
              label="Name"
              id="name"
              name="name"
              value={values.name}
              onChange={handleInputChange}
              //   error={errors.name || ""}
            />
            {errors?.name && <span className="err_text">{errors?.name}</span>}
          </div>
          <div className="project_edit_feature_input">
            <h4 className="project_text_area_label">Byte size</h4>

            <Select
              classNamePrefix="project_common_select_input"
              errorClassName="err_text"
              type="text"
              placeholder="Byte size..."
              label="Select byte size"
              id="byteSize"
              name="byteSize"
              // isClearable={true}
              isSearchable={true}
              onChange={(e) => handleSelectChange(e, "byteSize")}
              value={values.byteSize}
              //   error={errors?.salon_name || ""}
              options={bytesOptions}
            />
            {errors.byteSize && (
              <span className="err_text">{errors.byteSize}</span>
            )}
          </div>
          <div className="project_edit_feature_input">
            <h4 className="project_text_area_label">Config part</h4>

            <Select
              classNamePrefix="project_common_select_input"
              errorClassName="err_text"
              type="text"
              placeholder="Byte size..."
              label="Select config part"
              id="configPart"
              name="configPart"
              // isClearable={true}
              isSearchable={true}
              onChange={(e) => handleSelectChange(e, "configPart")}
              value={values.configPart}
              options={configOptions}
            />
            {errors.configPart && (
              <span className="err_text">{errors.configPart}</span>
            )}
          </div>
          <div className="project_edit_feature_input">
            <h4 className="project_text_area_label">Value Type</h4>
            <Select
              classNamePrefix="project_common_select_input"
              placeholder="Value type..."
              id={`valueType`}
              name="valueType"
              value={values.valueType}
              onChange={(e) => handleSelectChange(e, "valueType")}
              options={valueType}
            />

            {errors?.valueType && (
              <span className="err_text">{errors?.valueType}</span>
            )}
          </div>
          <div className="project_edit_feature_input mt-0">
            <Input
              className="project_edit_feature_input"
              errorClassName="err_text"
              type="text"
              placeholder="Enter value"
              label="Value"
              id="value"
              name="value"
              value={values.value}
              onChange={handleInputChange}
            />
            {errors?.value && <span className="err_text">{errors?.value}</span>}
          </div>

          <div className="mt-3 ms-3">
            <h4 className="project_text_area_label">Signed</h4>
            <div className="project_common_form_check_box force_input">
              <input
                type="checkbox"
                checked={values.signed}
                onChange={(e) => {
                  handleSelectChange(e, "signed");
                }}
                label="Signed"
              />
            </div>
            {errors?.signed && (
              <span className="err_text">{errors?.signed}</span>
            )}
          </div>
        </div>
        <Button
          className="project_submit_bottom_btn center_back_btn"
          buttonClassName="comman_btn"
          onClick={handleSubmit}
          text="Edit"
        />
      </div>
    </>
  );
};

export default EditProfileParameterForm;
