import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";

// File upload action
export const fileUpload = createAsyncThunk(
  "fileUpload",
  async (formData, thunkAPI) => {
    try {
      const response = await Axios.post(
        `/file/upload`,
        formData,
        authHeaders()
      );
      return response.data.key;
    } catch (error) {
      toast.error("File upload failed.", { autoClose: 3000 });
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

// ADD PROFILE
export const addProfile = createAsyncThunk("addProfile", async (data) => {
  try {
    const response = await Axios.post(`/profile`, data.data, authHeaders());
    data.onSuccess(response);
    return response.data.data;
  } catch (error) {
    data.onFailure(error);
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

// UPDATE PROFILE
export const updateProfile = createAsyncThunk("updateProfile", async (data) => {
  try {
    const response = await Axios.put(
      `/profile/${data.data.id}`,
      data.data.data,
      authHeaders()
    );
    data.onSuccess(response);
    return response.data.data;
  } catch (error) {
    data.onFailure(error);
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

//GET PROFILE
export const getProfile = createAsyncThunk("getProfile", async (data) => {
  try {
    const response = await Axios.get(
      `/profile?search=${data.search}&limit=${data.limits}&start=${data.start}`,
      authHeaders()
    );
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.message, {
      autoClose: 3000,
    });
    return error.response.data.message;
  }
});

//GET PROFILE DETAILS
export const getProfileDetails = createAsyncThunk(
  "getProfileDetails",
  async (data) => {
    try {
      const response = await Axios.get(`/profile/${data.data}`, authHeaders());
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 3000,
      });
      return error.response.data.message;
    }
  }
);

const initialState = {
  loader: false,
  error: null,
  profileList: [],
  profileDetail: {},
  profileListCount: 0,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // File Upload
    builder.addCase(fileUpload.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(fileUpload.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(fileUpload.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // ADD PROFILE
    builder.addCase(addProfile.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addProfile.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(addProfile.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //UPDATE PROFILE
    builder.addCase(updateProfile.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.loader = false;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // GET PROFILE
    builder.addCase(getProfile.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loader = false;
      state.profileList = action.payload.rows;
      state.profileListCount = action.payload.count;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // GET PROFILE DETAILS
    builder.addCase(getProfileDetails.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getProfileDetails.fulfilled, (state, action) => {
      state.loader = false;
      state.profileDetail = action.payload;
    });
    builder.addCase(getProfileDetails.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default profileSlice.reducer;
