import React from "react";
import moment from "moment";
import { tableHeadItemsForVersion } from "../../CommonComponent/TableHeads";
import { deleteIcon, userDefaultIcon } from "../../../../icons";

const VersionsList = ({ list, showDeleteModal, handleNavigateProfile }) => {
  return (
    <>
      {list?.length === 0 || !list ? (
        <p className="no_data_found">No data Found</p>
      ) : (
        <table className="custom_data_table">
          <thead className="custom_data_table_head">
            <tr>
              {tableHeadItemsForVersion.map((item, index) => {
                return (
                  <th
                    className="custom_data_table_heading text-center"
                    key={item.id}
                  >
                    {item.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="custom_data_table_body">
            {list?.map((item, i) => {
              return (
                <tr className="custom_data_table_row" key={item.id}>
                  <td className="custom_data_table_item table_item">
                    {item.version}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.hardware}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {" "}
                    {item.description}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.series}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.bin}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.app_config}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.motor_config}
                  </td>
                  <td className="custom_data_table_item table_item">
                    {item.created_at &&
                      moment(item.created_at).format("DD/MM/YYYY")}
                  </td>
                  <td className="custom_data_table_item table_item">
                    <div className="dtv_vehicle_col_btn_row d-flex align-items-center">
                      <button
                        aria-label="edit"
                        className="comman_btn"
                        onClick={(e) =>
                          handleNavigateProfile(item.id, item.version)
                        }
                      >
                        Profile
                      </button>
                      <button
                        aria-label="edit"
                        className="custom_data_tabl_delete_item_btn"
                        onClick={(e) => showDeleteModal(item.id)}
                      >
                        {deleteIcon}
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}{" "}
    </>
  );
};

export default VersionsList;
