import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CommonHeading from "../../CommonComponent/CommonHeading";
import Loader from "../../CommonComponent/Loader";
import Button from "../../CommonComponent/Button";
import { backBtnArrow } from "../../../../icons";
import {
  editVersionProfiles,
} from "../../../../store/slice/versionSlice";
import TextArea from "../../../../component/Common/CommonComponent/TextArea";
import { toast } from "react-toastify";

const EditVersionProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  const { id, profile, description } = location.state.item;
  const [values, setValues] = useState({
    description: (description != null) ? description : '',
  });
  const loading = useSelector((state) => state.version.loader);
  const onDescriptionChange = (e) => {
    setValues({
      ...values,
      description: e.target.value,
    });
  };

  const handleSubmit = () => {
    const payload = {
      description: values.description,
    };

    dispatch(
      editVersionProfiles({
        data: { id: id, data: payload },
        onSuccess: (res) => {
          navigate(-1);
          toast.success("Profile Description updated successfully!", {
            autoClose: 3000,
          });
        },
        onFailure: () => {},
      })
    );
  };

  return (
    <div className="project_edit_main_content">
      <CommonHeading heading={"EDIT VERSION PROFILE"} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="project_edit_main_link w-100">
            <div className="comman_center_btn_container">
              <button className="go_back_btn" onClick={() => navigate(-1)}>
                {backBtnArrow} Back
              </button>
            </div>
          </div>
          <div className="create_from_rowmb-3 p-5 pt-2">
            <div className="bg-white p-3 pt-2">
              <div className="d-flex justify-content-between flex-wrap w-100 ">
                <div className="project_edit_feature_input ">
                  <h4 className="project_text_area_label">Profile: {profile?.name}</h4>
                </div>
              </div>
              <div className="project_edit_feature_input w-75">
                <TextArea
                  name="description"
                  label="Description"
                  value={values?.description}
                  onChange={onDescriptionChange}
                  placeholder="About Profile"
                  rows="4"
                />
              </div>
              <Button
                className="project_submit_bottom_btn w-100"
                buttonClassName="comman_btn"
                onClick={handleSubmit}
                text="Submit"
              />
            </div>
        </div>
        </>
      )}
    </div>
  );
};

export default EditVersionProfilePage;
